<template>
  <b-card>
    <b-col
      cols="12"
    >
      <!-- form -->
      <validation-observer ref="affidavitDataForm">
        <b-form
          @submit.prevent="validationForm"
        >
          <b-row
            class="p-2 justify-content-center"
          >
            <b-col
              style="text-align: center"
              lg="12"
            >
              <h5 class="mt-1 mb-3">
                DECLARACIÓN JURADA
              </h5>
            </b-col>
            <b-col
              sm="12"
              md="8"
              class="mb-1"
            >
              <b-form-checkbox
                v-model="affidavit1"
                :disabled="!canEdit"
              >
                No he sido sancionado o investigado por procesos de lavado de activos y financiamiento del terrorismo y que los fondos involucrados en las operaciones de EFIDE, provienen de o serán destinados en actividades lícitas y no contravienen las normas respectivas.
              </b-form-checkbox>
            </b-col>
            <b-col
              sm="12"
              md="8"
              class="mb-1"
            >
              <b-form-checkbox
                v-model="affidavit2"
                :disabled="!canEdit"
              >
                Me comprometo a presentar a EFIDE, de ser el caso, la documentación que sustente el origen de los fondos involucrados en las operaciones.
              </b-form-checkbox>
            </b-col>
            <b-col
              sm="12"
              md="8"
              class="mb-1"
            >
              <b-form-checkbox
                v-model="affidavit3"
                :disabled="!canEdit"
              >
                Me comprometo a actualizar la información que fuera solicitada por EFIDE cuando lo requiera y a informar cualquier cambio, el mismo que tendrá efecto dentro de las 48 horas de recibida la comunicación.
              </b-form-checkbox>
            </b-col>
            <b-col
              sm="12"
              md="8"
              class="mb-1"
            >
              <b-form-checkbox
                v-model="affidavit4"
                :disabled="!canEdit"
              >
                Autorizo a EFIDE para que procese o consulte toda la información comercial y personal de conformidad con la Ley N° 29733, Ley de Protección de Datos Personales y Decreto Supremo N° 003-2013-JUS.
              </b-form-checkbox>
            </b-col>
            <b-col
              sm="12"
              md="8"
              class="mb-1"
            >
              <b-form-checkbox
                v-model="affidavit5"
                :disabled="!canEdit"
              >
                Certifico que las respuestas y declaraciones contenidas en estas fichas son verídicas y se ajustan a la realidad. De no serlo, cualquier declaración falsa hecha por el firmante y/o firmantes voluntariamente, invalida la presente solicitud y libera de toda responsabilidad y compromiso a EFIDE FACTORING, quedando la afiliación nula y sin efecto.
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row
            class="p-2 justify-content-center"
          >
            <b-col
              sm="6"
              md="3"
            >
              <!-- return button -->
              <b-button
                class="my-1"
                variant="secondary"
                :to="{name:'Documents-data'}"
                block
              >
                Volver
              </b-button>
            </b-col>
            <b-col
              sm="12"
              md="3"
            >
              <!-- submit button -->
              <b-button
                class="my-1"
                variant="primary"
                block
                type="submit"
              >
                {{ canEdit ? "Guardar" : "Siguiente" }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-col>
    <b-col
      v-if="!validate"
    >
      <b-alert
        v-if="!canEdit"
        variant="warning"
        show
      >
        <div class="alert-body">
          <span>Tienes que completar todos los campos obligatorios para poder finalizar el registro e ingresar a la plataforma.</span>
        </div>
      </b-alert>
      <b-alert
        v-else
        variant="info"
        show
      >
        <div class="alert-body">
          <span>Tienes que completar todos los campos obligatorios para poder finalizar la edición y retornar a tu panel.</span>
        </div>
      </b-alert>
    </b-col>
    <b-col
      v-else
    >
      <b-button
        class="my-1"
        variant="success"
        block
        :disabled="processing"
        @click="finishRegister"
      >
        <b-spinner
          v-if="processing"
          style="margin-bottom: 2px"
          small
        /> {{ processing ? 'Procesando...' : finalLabel }}
      </b-button>
    </b-col>
  </b-card>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver } from 'vee-validate'
import {
  BForm, BCard, BButton, BRow, BCol, BFormCheckbox, BAlert,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BAlert,
  },
  data() {
    return {
      processing: false,
      email: JSON.parse(localStorage.getItem('userData')).email,
      ruc: JSON.parse(localStorage.getItem('userData')).ruc,
      clientType: JSON.parse(localStorage.getItem('userData')).clientType,
      canEdit: JSON.parse(localStorage.getItem('userData')).canEdit || false,
      affidavit1: false,
      affidavit2: false,
      affidavit3: false,
      affidavit4: false,
      affidavit5: false,
      // Validation rules
      required,
      // Completed rules
      personalCompleted: false,
      contactCompleted: false,
      professionalCompleted: false,
      pepCompleted: false,
      relativesCompleted: false,
      partnerCompleted: false,
      bankCompleted: false,
      bankAccountsCompleted: false,
      documentsCompleted: false,
      affidavitCompleted: false,
      sectionsCompleted: false,
      userData: JSON.parse(localStorage.getItem('userData')),
      userType: null,
      vuex: JSON.parse(localStorage.getItem('vuex')),
    }
  },
  computed: {
    validate() {
      return this.sectionsCompleted && this.affidavitCompleted
    },
    finalLabel() {
      return this.canEdit ? 'Finalizar edición' : 'Finalizar Registro'
    },
  },
  created() {
    const dataU = JSON.parse(localStorage.getItem('userData'))
    this.userType = dataU.type
    if (dataU.type === 0) {
      this.canEdit = true
    }
    this.affidavit1 = dataU.affidavitData && dataU.affidavitData.affidavit1 ? dataU.affidavitData.affidavit1 : false
    this.affidavit2 = dataU.affidavitData && dataU.affidavitData.affidavit2 ? dataU.affidavitData.affidavit2 : false
    this.affidavit3 = dataU.affidavitData && dataU.affidavitData.affidavit3 ? dataU.affidavitData.affidavit3 : false
    this.affidavit4 = dataU.affidavitData && dataU.affidavitData.affidavit4 ? dataU.affidavitData.affidavit4 : false
    this.affidavit5 = dataU.affidavitData && dataU.affidavitData.affidavit5 ? dataU.affidavitData.affidavit5 : false
    // Complete status for each form
    this.personalCompleted = dataU.personalData && dataU.personalData.completed ? dataU.personalData.completed : false
    this.contactCompleted = dataU.contactLocationData && dataU.contactLocationData.completed ? dataU.contactLocationData.completed : false
    this.professionalCompleted = dataU.professionalData && dataU.professionalData.completed ? dataU.professionalData.completed : false
    this.pepCompleted = dataU.pepData && dataU.pepData.completed ? dataU.pepData.completed : false
    this.relativesCompleted = dataU.familyData && dataU.familyData.completed ? dataU.familyData.completed : false
    this.partnerCompleted = dataU.efidePartnerData && dataU.efidePartnerData.completed ? dataU.efidePartnerData.completed : false
    this.bankCompleted = dataU.bankData && dataU.bankData.completed ? dataU.bankData.completed : false
    this.bankAccountsCompleted = dataU.mainBankAccountsData && dataU.mainBankAccountsData.completed ? dataU.mainBankAccountsData.completed : false
    this.documentsCompleted = dataU.documentsData && dataU.documentsData.completed ? dataU.documentsData.completed : false
    this.affidavitCompleted = dataU.affidavitData && dataU.affidavitData.completed ? dataU.affidavitData.completed : false
    this.sectionsCompleted = this.personalCompleted && this.contactCompleted && this.professionalCompleted && this.pepCompleted && this.relativesCompleted && this.partnerCompleted && this.bankCompleted && this.bankAccountsCompleted && this.documentsCompleted
  },
  methods: {
    validationForm() {
      if (this.userType === 1 && !this.canEdit) {
        this.affidavitCompleted = true
        store.dispatch(
          'elementVerticalMenu/setStatusStep',
          { step: 'Affidavit-data', status: true },
          { root: true },
        )
      } else {
        this.$refs.affidavitDataForm.validate().then(success => {
          if (success) {
            useJwt.affidavitData({
              affidavit1: this.affidavit1,
              affidavit2: this.affidavit2,
              affidavit3: this.affidavit3,
              affidavit4: this.affidavit4,
              affidavit5: this.affidavit5,
              email: this.email,
              ruc: this.ruc,
            })
              .then(({ data }) => {
                const user = data.user.value
                this.affidavitCompleted = user.affidavitData.completed
                localStorage.setItem('userData', JSON.stringify(user))
                store.dispatch(
                  'elementVerticalMenu/setStatusStep',
                  { step: 'Affidavit-data', status: user.affidavitData.completed },
                  { root: true },
                )
                this.userData = user
              })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Éxito',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'Se ha guardado tu información con éxito.',
                    autoHideDelay: 8000,
                  },
                })
              })
              .catch(error => {
                console.log('errores', error)
                this.$refs.personalDataForm.setErrors(error.response.data.error)
              })
          }
        })
      }
    },
    async finishRegister() {
      if (this.userType === 1 && !this.canEdit) {
        this.userData.registerComplete = true
        localStorage.setItem('userData', JSON.stringify(this.userData))
        this.vuex.elementVerticalMenu = await store.dispatch('elementVerticalMenu/getData', null, { root: true })
        window.location.href = '/cliente/panel'
      } else {
        this.processing = true
        useJwt.finishRegister({
          ruc: this.ruc,
          email: this.email,
          clientType: this.clientType,
        })
          .then(({ data }) => {
            this.processing = false
            const user = data.user.value
            localStorage.setItem('userData', JSON.stringify(user))
            if (user.registerComplete) {
              this.userData = user
              this.$router.replace('/cliente/panel')
                .then(() => {
                  window.location.reload()
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Éxito',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: this.canEdit ? 'Has finalizado tu edición con éxito.' : 'Has finalizado tu registro con éxito.',
                      autoHideDelay: 8000,
                    },
                  })
                })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: 'Error al registrarte en Efide.',
                  autoHideDelay: 8000,
                },
              })
            }
          })
          .catch(error => {
            this.processing = false
            console.log('errores', error)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: 'Error al registrarte en Efide.',
                autoHideDelay: 8000,
              },
            })
          })
      }
    },
  },
}
</script>

<style lang="scss">
</style>
